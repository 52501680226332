import axios from "axios";

import {
	ElMessage,
	ElLoading
} from 'element-plus'

const axiosInstance = axios.create({
    // baseURL: "",
    headers: {
        'Content-Type': 'application/json',
    }
})
axiosInstance.interceptors.request.use(
    function (config) {
        // 次接口文件下载
        config.responseType = 'blob'
        config.headers['token'] = localStorage.getItem('token')
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default function (app) {
    app.config.globalProperties.Axz = function (url, method = 'get', data) {
        axiosInstance
            .request(
                {
                    url: url,
                    method: method,
                    data: data,
                    params: method == 'get' ? data : [],
                    queryObj: method == 'get' ? data : []
                }
            )
            .then((res) => {
                // 文件下载
                const { data, headers } = res
                const fileName = headers['content-disposition'].split("=").pop()
                const blob = new Blob([data], { type: headers['content-type'] })
                let dom = document.createElement('a')
                let url = window.URL.createObjectURL(blob)
                dom.href = url
                dom.download = decodeURI(fileName)
                dom.style.display = 'none'
                document.body.appendChild(dom)
                dom.click()
                dom.parentNode.removeChild(dom)
                window.URL.revokeObjectURL(url)
            }).catch((err) => {
                console.log(err,"err")
                if (err.response.status === 403) {
                    ElMessage.warning("暂无权限！")
                }
            })
    }

}

