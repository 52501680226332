import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/',
    name: 'main',
    meta: {
      title: '母版'
    },
    component: () => import('@/views/layout/main.vue'),
    'children': [
      {
        path: '/data',
        name: 'data',
        meta: {
          title: '数据统计',
          icon: "Histogram"
        },
        component: () => import('@/views/data/index.vue')
      },
      {
        path: '/contrast',
        name: 'contrast',
        meta: {
          title: '数据对比',
          icon: "Histogram"
        },
        component: () => import('@/views/contrast/index.vue')
      },
      {
        path: '/manage',
        name: 'manage',
        meta: {
          title: '投诉管理',
          icon: "Platform"
        },

        component: () => import('@/views/manage/index.vue'),

      },
      {
        path: '/detail',
        name: 'detail',
        meta: {
          title: '投诉详情',
          icon: "Platform",
          routrs: [
            {
              path: '/manage',
              title: '投诉管理'
            }
          ]
        },
        component: () => import('@/views/manage/details/index.vue')
      },
      {
        path: '/password',
        name: 'password',
        meta: {
          title: '修改密码',
          icon: "ChatDotRound"
        },
        component: () => import('@/views/password/index.vue')
      },
      {
        path: '/handle',
        name: 'handle',
        meta: {
          title: '督办消息',
          icon: "ChatLineRound"
        },
        component: () => import('@/views/handle/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          title: '用户管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/user/index.vue')
      },
      {
        path: '/part',
        name: 'part',
        meta: {
          title: '角色管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/part/index.vue')
      },
      {
        path: '/fam',
        name: 'fam',
        meta: {
          title: '科室管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/family/index.vue')
      },
      {
        path: '/genre',
        name: 'genre',
        meta: {
          title: '类型管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/genre/index.vue')
      },
      {
        path: '/pattern',
        name: 'pattern',
        meta: {
          title: '方式管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/pattern/index.vue')
      },
      {
        path: '/station',
        name: 'station',
        meta: {
          title: '岗位类型管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/station/index.vue')
      },
      {
        path: '/genreAdmin',
        name: 'genreAdmin',
        meta: {
          title: '岗位类型管理',
          icon: "Setting"
        },
        component: () => import('@/views/settings/genreAdmin/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// 前置路由守卫
router.beforeEach((to, from, next) => {
  //权限控制登录

  const token = localStorage.getItem('token');
  var quan = JSON.parse(localStorage.getItem('quanxian'));

  if (to.name != 'login' && !token) {
    router.push('/login')
    return
  }

  // 默认定位到data
  if (to.path.substr(-1) == '/') {
    if (quan == 0) {
      next('/manage')
    } else {
      next('/login')
    }
  }

  next();
});

export default router
