<template>
  <router-view />
</template>
<script setup>

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const resizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends resizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};
</script>
<style>
* {
  padding: 0px;
  box-sizing: border-box;
  margin: 0px;
}

a {
  text-decoration: none;
  /* 去除下划线 */
  color: inherit;
  /* 继承父元素的颜色 */
  cursor: pointer;
  /* 修改鼠标指针样式 */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;


}

.tabletitles {
  margin: 0px 0px 20px;
  font-size: 18px;
  color: #333;
}
</style>
